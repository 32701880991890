<!-- 分类新增/编辑 -->
<template>
    <div class="good_add">
        <header>
            <span class="back" @click="$router.back()">
                <i class="el-icon-arrow-left" style="font-size:14px;"></i>
                返回
                <i class="line">|</i>
            </span>
            <span class="titile">{{ !$route.query.id ? "添加分类" : "编辑分类" }}</span>
        </header>
        <nav>
            <div class="class_wrap">
                <div class="class_container">
                    <div class="class_head">
                        <p>{{ !$route.query.id ? "添加分类" : "编辑分类" }}</p>
                    </div>
                    <div class="class_addnav">
                        <el-form label-width="160px" :rules="sifiedRules" :model="classEditInfo" ref="sifiedForm">
                            <el-form-item label="父级名称" prop="parent_id" v-show="(((getOwnArticleCatInfo.parent_id != 0)))">
                                <div class="class_parent">
                                    <el-cascader filterable clearable v-model="classEditInfo.parent_id" ref="cascader"
                                        :options="optionparent"
                                        :props="{ value: 'id', label: 'name', children: 'children', checkStrictly: true, emitPath: false, leaf: 'hasChildren' }"
                                        @change="handleChange">
                                    </el-cascader>
                                </div>
                            </el-form-item>
                            <el-form-item label="分类名称" prop="name">
                                <div class="class_input">
                                    <el-input v-model="classEditInfo.name"></el-input>
                                </div>
                            </el-form-item>
                            <el-form-item label="排序" prop="sort">
                                <div class="class_input">
                                    <el-input v-model="classEditInfo.sort" type="number"
                                        :disabled="hasPerm(['article.category.update.bottom'])"
                                        placeholder="排序值越小排序越靠前"></el-input>
                                </div>
                            </el-form-item>
                            <el-form-item label="是否显示" prop="is_show">
                                <el-radio-group v-model="classEditInfo.is_show">
                                    <el-radio :label="1">显示</el-radio>
                                    <el-radio :label="0">隐藏</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </div>
        </nav>
        <diy-footer-button v-if="hasPerm(['article.category.update', 'article.category.store'])">
            <el-button type="primary" @click="handleSubmit">{{ $route.query.id ? '更新' : '保存'
            }}</el-button>
        </diy-footer-button>
    </div>
</template>
<script>
import { Message } from 'element-ui';
import common from '../../common/common';

export default {
    data() {
        return {
            classEditInfo: {
                parent_id: "",//父级id
                name: "",//分类名称
                is_show: 1,//是否显示
                sort: 0,//排序
            },
            optionparent: [],//联动数据
            sifiedRules: { //rules
                parent_id: {
                    required: true,
                    message: '请选择父级名称',
                    trigger: ['change', 'blur']
                },
                name: {
                    required: true,
                    message: '请输入分类名称',
                    trigger: 'blur'
                },
                is_show: {
                    required: true,
                    message: '请选择是否显示',
                    trigger: ['change', 'blur']
                },
                sort: {
                    required: true,
                    message: '请输入排序数值',
                    trigger: ['blur']
                }
            },
        }
    },
    created() {
        this.getClassArticleList();
        if (this.$route.query.id) {
            this.getOwnArticleCatInfo(this.$route.query.id);
        }
    },
    methods: {
        // 判断父级是否存在子级并控制层级-------封装到common.js
        // changeCascaderSource(arr, flag, level = 0) {
        //     // 数据只展示到第二级别，需要像数据中添加level字段进行标识，以达到不允许在二级后操作
        //     // 需要判断是否有子集，是否展示展开叶子标识
        //     return arr.map(item => {
        //         // 像第一级别添加level
        //         // item.level = level ? ++level : ++item.pid; //只针对当前业务，可以手动传参
        //         item.level = level;
        //         // item.leaf = level >= tier; // 控制层级
        //         // 判断是否有子集并添加数据
        //         if (item.hasOwnProperty('children') && item.children.length != 0) {
        //             item['hasChildren'] = true;
        //             // 需要循环子集添加level
        //             let newLevel = item.level; //防止影响原数据
        //             this.changeCascaderSource(item.children,flag,++newLevel);
        //         } else {
        //             item['hasChildren'] = false;
        //             delete item.children; //需删除children，否则会出现空白页
        //             // 根据业务需求，是否能操作最后一个子项
        //             (item.level != 0 && (item.disabled = flag));
        //         }

        //         return item;
        //     })
        // },
        async getClassArticleList() {
            const { data: { list } } = await this.$get(this.$apis.contentArticleClassApi);
            const result = common.changeCascaderSource(list, true, 3, 1);
            this.$set(this, "optionparent", [{ id: 0, name: "无" }, ...result]);
        },
        // 获取单个文章分类详情
        async getOwnArticleCatInfo(id) {
            const { data } = await this.$get(`${this.$apis.contentArticleClassApi}/${id}`);
            //console.log(data);
            this.$nextTick(() => {
                this.$set(this, 'classEditInfo', { ...data, parent_id: Number(data.parent_id) });
                this.getOwnArticleCatInfo.parent_id = Number(data.parent_id);
                this.$forceUpdate();
            })
        },
        // 多级联动change事件
        handleChange() { },
        // 取消
        handleCancel() {
            this.$refs['sifiedForm']['resetFields']();
            this.$router.push('/channel/content/article_class');
        },
        // 提交 / 更新
        handleSubmit() {
            this.$refs['sifiedForm']['validate'](async valid => {
                if (valid) {
                    const { code, message } = await this[this.$route.query.id ? '$put' : '$post'](`${this.$apis.contentArticleClassApi}/${this.$route.query.id || ''}`, this.classEditInfo);
                    Message[(() => code == 200 ? 'success' : 'error')()]({
                        message: `${code == 200 ? `${this.$route.query.id ? '更新' : '添加'}文章分类成功!` : message}`,
                        offset: 200
                    })
                    code == 200 && this.handleCancel();
                }
            })
        }
    },
}
</script>
<style scoped lang="less">
// @import url("css/ditch.css");

.el-radio__input.is-checked .el-radio__inner {
    background-color: #fff !important;
    width: 18px;
    height: 18px;
}

.el-radio__input .el-radio__inner {
    width: 18px;
    height: 18px;
}

.el-radio__input.is-checked .el-radio__inner::after {
    width: 8px;
    height: 8px;
    background-color: var(--fontColor);
}

.box_right {
    width: calc(100% - 106px);
    height: 100vh;
    margin-left: 106px;
    box-sizing: border-box;
    display: flex;
    min-height: 0;
    flex: 1 1 auto;
}

.goods_left {
    width: 134px;
    flex: 0 0 134px;
    background: #fff;
    height: 100vh;
    z-index: 10;
    border-right: 1px solid #eee;
    position: fixed;
    top: 0;
    left: 106px;
    box-sizing: border-box;
    margin-top: 50px;
}

.goods_headline {
    height: 50px;
    line-height: 50px;
    padding-left: 23px;
    font-size: 14px;
    color: #333;
    border-bottom: 1px solid #eee;
    padding-top: 0;
}

nav {
    background: #fff;
    display: flex;
    flex: 1;
    margin-top: 20px;
    padding-bottom: 60px;
}

.class_wrap {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.class_container {
    background: #fff;
    border-radius: 4px;
    position: relative;
    margin: 24px;
    transition: all .2s ease-in-out;
    margin-top: 0;
}

.class_head {
    border-bottom: 1px solid #f2f2f2;
    padding: 8px 20px;
    line-height: 1;
}

.class_head p {
    display: inline-block;
    width: auto;
    line-height: 28px;
    font-size: 16px;
    color: #333;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 700;
}

.class_addnav {
    margin: 20px;
}


.class_input>p {
    color: #d9534f;
}

.sortTips {
    color: #636c72;
    font-size: 13px;
}

.skeletonize {
    width: 50px;
    height: 50px;
    line-height: 50px;
    margin-right: 10px;
    border: 1px dashed #ededed;
    text-align: center;
    color: #dad9d9;
    float: left;
    position: relative;
    cursor: pointer;
    background: white;
}

.skeletonize_toast {
    font-size: 12px;
    color: #999;
    padding: 4px 0;
    box-sizing: border-box;
    line-height: 1;
}

.skeletonize img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}



.link_wrap {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
}

.link_head {
    display: flex;
}

.link_head>span {
    padding: 5px 7px;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
    color: #464a4c;
    text-align: center;
    background-color: #eceeef;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 4px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    white-space: nowrap;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.link_head>.el-select {
    display: flex;
    width: 400px;
}

.link_head>.el-select>.el-input>.el-input__inner {

    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border-left: none;
}

.linkTips {
    font-size: 13px;
    color: var(--fontColor);
    margin-top: 10px;
}

footer {
    position: fixed;
    left: 0;
    padding-left: 160px;
    box-sizing: border-box;
    right: 0;
    bottom: 0;
    width: 100%;
    margin: 0 24px;
    height: auto;
    border-top: 1px solid #f1f1f1;
    z-index: 6;
    background-color: #fff;
}

.class_foot {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.attr_foot_cancel {
    border: 1px solid transparent;
    color: #666;
    background-color: #fff;
    border-color: #ededed;
    margin-right: 20px;
}
</style>